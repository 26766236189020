export const geoApiOptions = {
	method: 'GET',
	headers: {
		'X-RapidAPI-Key': '97c6b564f2msh392439e97479832p1c4158jsn267fe11fae77',
		'X-RapidAPI-Host': 'wft-geo-db.p.rapidapi.com'
	}
};
    export const GEO_API_URL = "https://wft-geo-db.p.rapidapi.com/v1/geo";
    export const WEATER_API_URL = "https://api.openweathermap.org/data/2.5";
    export const WEATHER_API_KEY ="b149fe669db3c442a3917cddfc52b8f3";
