import React from 'react'
import './Navbar.css'


const Navbar = () => {
  return (
    <div className='nav'>
      <button className='btn'>SingIn</button>
      <button className='btn'>SingUp</button>

      
    </div>
  )
}

export default Navbar
