import "./App.css";
import Search from "./components/search/Search";
import CurrentWeather from "./components/current-weather/current-weather";
import Forecast from "./components/forecast/forecast";
import {WEATER_API_URL,WEATHER_API_KEY} from './Api'
import { useState } from "react";
import image from "./image/bg2.jpg"; 
import Navbar from "./Navbar/Navbar";


function App() {
 
  const [currentWeather, setCurrentWeather] = useState(null);
  const [forecast, setForecast] = useState(null);

  const handleOnSearchChange = (searchData) => {
    const [lat, lon] = searchData.value.split(" ");

    const currentWeatherFetch = fetch(
      `${WEATER_API_URL}/weather?lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}&units=metric`
    );
    const forecastFetch = fetch(
      `${WEATER_API_URL}/forecast?lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}&units=metric`
    );

    Promise.all([currentWeatherFetch, forecastFetch])
      .then(async (response) => {
        const weatherResponse = await response[0].json();
        const forecastResponse = await response[1].json();

        setCurrentWeather({ city: searchData.label, ...weatherResponse });
        setForecast({ city: searchData.label, ...forecastResponse });
      })
      .catch((err) => console.log(err));

  };

  console.log(forecast);

  return (
    <div className="bg" style={{ backgroundImage:`url(${image})` }}>
    <div className="container">
      <div className="box">
       
      <Navbar/>
      
      
      
      
      <Search onSearchChange={handleOnSearchChange} />
      {currentWeather && <CurrentWeather data={currentWeather} />}
      {forecast && <Forecast data = {forecast}/>} 

      

      </div>
    </div>  
    </div>
  );
  
}


export default App;

